import React, { useEffect, useState } from "react";
import firebase_app from "./firebase"; // Adjust the path to your configuration file
import { getDatabase, ref, onValue } from "firebase/database";

type Status = {
  time: number;
  current_sequence_name: string;
  current_playlist_name: string;
  allow_play: boolean;
  counter: number;
  status_name: string;
  next_scheduled_playlist: string;
  uptime: number;
  seconds_played: string;
  seconds_remaining: string;
};

type LocationData = {
  status: Status;
};

type Data = {
  [key: string]: LocationData;
};

const OFFLINE_THRESHOLD = 10000;

export default function DisplayTable() {
  const [data, setData] = useState<Data>({});
  const [syncTimes, setSyncTimes] = useState<Record<string, number>>({});
  const [lastTimes, setLastTimes] = useState<Record<string, number>>({});

  useEffect(() => {
    const database = getDatabase(firebase_app);
    const collectionRef = ref(database, "/locations/");

    const unsubscribe = onValue(collectionRef, (snapshot) => {
      const dataItem = snapshot.val() as Data;
      if (dataItem) {
        setData(dataItem);

        setLastTimes((prevLastTimes) => {
          const newLastTimes: Record<string, number> = { ...prevLastTimes };
          const updatedSyncTimes: Record<string, number> = { ...syncTimes };

          Object.entries(dataItem).forEach(([locationName, locationData]) => {
            const locationTime = locationData.status.time;

            if (prevLastTimes[locationName] !== locationTime) {
              newLastTimes[locationName] = locationTime;
              updatedSyncTimes[locationName] = Date.now() - locationTime;
            } else if (Date.now() - locationTime > OFFLINE_THRESHOLD) {
              updatedSyncTimes[locationName] = Date.now() - locationTime;
            }
          });

          setSyncTimes(updatedSyncTimes);
          return newLastTimes;
        });
      }
    });

    return () => unsubscribe();
  }, [syncTimes]);

  const formatSyncTime = (elapsed: number): string => {
    if (elapsed > 24 * 60 * 60 * 1000) {
      return `${(elapsed / (24 * 60 * 60 * 1000)).toFixed(2)} days`;
    } else if (elapsed > 60 * 60 * 1000) {
      return `${(elapsed / (60 * 60 * 1000)).toFixed(2)} hours`;
    } else if (elapsed > 60 * 1000) {
      return `${(elapsed / (60 * 1000)).toFixed(2)} minutes`;
    } else if (elapsed > 1000) {
      return `${(elapsed / 1000).toFixed(2)} seconds`;
    }
    return `${elapsed} ms`;
  };

  const formatUptime = (uptime: number): string => {
    const date = new Date(uptime);
    return date.toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  };

  return (
    <div>
      <h1>Realtime Data</h1>
      {Object.entries(data).map(([locationName, locationData]) => {
        const status = locationData.status;
        const delay = syncTimes[locationName];
        const isOffline = delay > OFFLINE_THRESHOLD;
        const syncStatusText = isOffline ? "Offline" : "OK";

        return (
          <div key={locationName} style={{ marginBottom: "20px" }}>
            <h2>{locationName}</h2>
            <table
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "100%",
              }}
              cellPadding={10}
            >
              <thead>
                <tr>
                  <th>Sync</th>
                  <th>Status Name</th>
                  <th>Current Playlist Name</th>
                  <th>Current Sequence Name</th>
                  <th>Time Elapsed / Remaining</th>
                  <th>Allow Play</th>
                  <th>Counter</th>
                  <th>Next Scheduled Playlist</th>
                  <th>Uptime</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    backgroundColor: isOffline ? "red" : "green",
                    color: "white",
                  }}
                >
                  <td>{syncStatusText} ({formatSyncTime(delay)})</td>
                  <td>{status.status_name}</td>
                  <td>{status.current_playlist_name || "N/A"}</td>
                  <td>{status.current_sequence_name || "N/A"}</td>
                  <td>
                    {status.seconds_played}s / {status.seconds_remaining}s
                  </td>
                  <td>{status.allow_play ? "Yes" : "No"}</td>
                  <td>{status.counter}</td>
                  <td>{status.next_scheduled_playlist}</td>
                  <td>{formatUptime(status.uptime)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}
