// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-kYwEzNKLqe8yoINiqSEbMay9DEf-Lnc",
  authDomain: "christmas-cdad0.firebaseapp.com",
  databaseURL: "https://christmas-cdad0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "christmas-cdad0",
  storageBucket: "christmas-cdad0.appspot.com",
  messagingSenderId: "278802999760",
  appId: "1:278802999760:web:4b0c533ef077dd47ea71d5",
  measurementId: "G-ZREWXXT7QG"
};

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebase_app);

export default firebase_app;